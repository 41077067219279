import React, { Component } from "react"
import Img from "gatsby-image"
import styles from "./lightbox.module.scss"

class Lightbox extends Component {
  state = {
    images: this.props.images,
    totalImages: null,
    videos: this.props.videos,
    totalVideos: null,
    currentIndex: 0,
    lightbox: this.props.lightbox,
  }

  gotoNext = () => {
    this.props.images
      ? this.setState({
          currentIndex:
            this.state.currentIndex === this.state.totalImages
              ? this.state.totalImages
              : this.state.currentIndex + 1,
        })
      : this.setState({
          currentIndex:
            this.state.currentIndex === this.state.totalVideos - 1
              ? this.state.totalVideos - 1
              : this.state.currentIndex + 1,
        })
  }

  gotoPrevious = () => {
    this.props.images
      ? this.setState({
          currentIndex:
            this.state.currentIndex !== 0 ? this.state.currentIndex - 1 : 0,
        })
      : this.setState({
          currentIndex:
            this.state.currentIndex !== 0 ? this.state.currentIndex - 1 : 0,
        })
  }

  componentDidMount() {
    this.props.images
      ? this.setState({ totalImages: this.props.images.length - 1 })
      : this.setState({ totalImages: 0 })
    this.props.videos
      ? this.setState({ totalVideos: this.props.videos.length })
      : this.setState({ totalVideos: 0 })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        currentIndex: Number(this.props.id),
        lightbox: this.props.lightbox,
      })
    }
  }

  render() {
    return (
      <div
        className={`modal fade ${styles.lightbox}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog modal-dialog-centered ${styles.dialog}`}
          role="document"
        >
          <div className={`modal-content ${styles.content}`}>
            <div className={`modal-header ${styles.header}`}>
              <button
                type="button"
                className="btn text-light"
                onClick={() => this.setState({ lightbox: false })}
                data-dismiss="modal"
              >
                X
              </button>
            </div>
            <div
              className={`${styles.main}`}
              data-toggle="modal"
              data-target="#exampleModalCenter"
            >
              {!this.state.images ? (
                <iframe
                  id="ytplayer"
                  className={styles.player}
                  type="text/html"
                  width="640"
                  height="360"
                  title={this.state.videos[this.state.currentIndex].node.title}
                  src={
                    this.state.lightbox
                      ? `https://www.youtube.com/embed/${
                          this.state.videos[this.state.currentIndex].node
                            .videoId
                        }?autoplay=1&origin=https://phoenixarchitecture.in`
                      : ""
                  }
                />
              ) : (
                <Img
                  fluid={
                    this.state.images[`${this.state.currentIndex}`].node
                      .childImageSharp.fluid
                  }
                />
              )}
            </div>
            <div className={`modal-footer ${styles.footer}`}>
              <button
                type="button"
                className={`btn text-light ${styles.arrows}`}
                onClick={this.gotoPrevious}
              >
                &#10094;
              </button>
              <button
                type="button"
                className={`btn text-light ${styles.arrows}`}
                onClick={this.gotoNext}
              >
                &#10095;
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Lightbox
