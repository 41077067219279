import React from "react"

import Layout from "../../components/layoutGallery"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./walkthroughs.module.scss"
import LightBox from "../../components/lightbox"
// import LightboxVideo from '../../components/lightboxVideo'

export const query = graphql`
  query {
    allYoutubeVideo {
      edges {
        node {
          id
          title
          description
          videoId
          localThumbnail {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

class WalkthroughsPage extends React.Component {
  state = {
    id: "",
    lightbox: false,
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="Work-Walkthroughs"
          keywords={[
            `work`,
            `walkthrough`,
            `samples`,
            `services`,
            `architecture`,
          ]}
        />
        <div className={styles.walkthroughsPage}>
          <h4 className={`${styles.heading} text-secondary`}>
            work / walkthroughs
          </h4>
          <div className={styles.gallery}>
            {data.allYoutubeVideo.edges.map((image, index) => {
              return (
                <div
                  key={image.node.id}
                  id={index}
                  className={styles.imageWrapper}
                  // style={{ width:`25vw` }}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  data-backdrop="static"
                  data-keyboard="false"
                  onClick={e => {
                    this.setState({
                      id: e.target.parentNode.parentNode.parentNode.id,
                      lightbox: true,
                    })
                  }}
                >
                  <Img
                    className={styles.item}
                    fluid={image.node.localThumbnail.childImageSharp.fluid}
                    alt={`walkthrough thumbnail image ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <LightBox
          videos={data.allYoutubeVideo.edges}
          lightbox={this.state.lightbox}
          id={this.state.id}
        />
      </Layout>
    )
  }
}

export default WalkthroughsPage
